<template>
  
    <div>
        
      <table :id="table" class="table table-striped dt-responsive w-100 mb-3">
        
      </table>
      
    </div>

  </template>
  
  <script>

  import "datatables.net/js/jquery.dataTables.min.js";
  import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
  import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
  import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
  import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
  import $ from "jquery";
  
  export default {

    props: ['data', 'columns', 'route', 'table'],
  
    mounted() {
      this.initializeDataTable();
    },
  
    updated() {
      this.initializeDataTable();
    },
  
    methods: {
      initializeDataTable() {
        // Destroy existing DataTable if it exists
        if ($.fn.DataTable.isDataTable(`#${this.$props.table}`)) {
          $(`#${this.$props.table}`).DataTable().destroy();
        }
  
        // Initialize DataTable
        $(`#${this.$props.table}`).DataTable({
          data: this.data,
          columns: this.columns,
          createdRow: (row, data) => {

            $(row).find('.edit-icon').on('click', () => {
    
                this.$router.push({'name' : this.route, params:{id: data.id}})

            });

        }

        });
      },
    }

  };

  </script>
  